<template>
    <v-app>
        <w-snackbar-queue
            :items="snackbarItems"
            @remove="removeSnackbarItem"
            right
            top
        ></w-snackbar-queue>
        <centered-layout
            :loading="loading"
            loading-message="Please wait..."
            v-if="(loading || !valid) && !notFound && !errorPage"
        >
            <page-message :logo-path="clientLogo" v-if="!valid && !loading">
                There was a problem displaying this page.
            </page-message>
        </centered-layout>
        <router-view v-else></router-view>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {ERROR_PAGE, NOT_FOUND_PAGE} from '@/router/route-names'
    import {AlertTheme} from '@wf/assets/theme/theme'
    import WSnackbarQueue from '@wf/components/WSnackbarQueue'
    import CenteredLayout from '@/layouts/CenteredLayout'
    import PageMessage from '@/components/PageMessage'

    export default {
        name: 'App',
        data: () => ({
            snackbarItems: []
        }),
        metaInfo () {
            return {
                title: 'Secure',
                titleTemplate: '%s'
            }
        },
        components: {
            PageMessage,
            CenteredLayout,
            WSnackbarQueue
        },
        methods: {
            ...mapActions('errors', ['removeErrors']),
            ...mapActions('auth', ['setCredentials', 'checkCredentials', 'setLoading', 'resetAuth']),
            handleLoad () {
                const vm = this

                if (!vm.valid && !vm.notFound && !vm.errorPage) {
                    vm.setLoading(true)
                    vm.checkCredentials(vm.credentials).then((response) => {
                        vm.$vuetify.theme = response.tenant.data.theme
                        setTimeout(() => {
                            vm.setLoading(false)
                        }, 100)
                    }, () => {
                        vm.setLoading(false)
                    })
                }
            },
            addSnackbarItem (error) {
                this.snackbarItems.push(error)
            },
            removeSnackbarItem (uuid) {
                const vm = this
                const index = _.findIndex(vm.snackbarItems, {uuid})

                vm.snackbarItems.splice(index, 1)
            },
            addWindsorFrameworkTheme () {
                _.extendOwn(this.$vuetify.theme, AlertTheme)
            }
        },
        computed: {
            ...mapGetters('errors', ['errors']),
            ...mapGetters('auth', ['clientName', 'clientLogo', 'redirectUrl', 'valid', 'theme', 'loading']),
            credentials () {
                const vm = this
                return {
                    client_id: vm.$route.query.client_id,
                    public_key: vm.$route.query.public_key
                }
            },
            notFound () {
                const vm = this
                return vm.$route.name === NOT_FOUND_PAGE
            },
            errorPage () {
                const vm = this
                return vm.$route.name === ERROR_PAGE
            }
        },
        watch: {
            errors (errors) {
                const vm = this

                _.each(errors, (data) => {
                    let error = vm.$clone(data)
                    error = {
                        ...error,
                        message: error.error ? error.error : error.message
                    }
                    if (error.showSnackbar) {
                        vm.addSnackbarItem(error)
                    }
                    vm.removeErrors([data.uuid])
                })
            },
            credentials (credentials) {
                const vm = this
                vm.setCredentials(credentials)
                vm.handleLoad()
            },
            theme (theme) {
                const vm = this
                if (!_.isEmpty(theme)) {
                    vm.$vuetify.theme = theme
                    vm.addWindsorFrameworkTheme()
                }
            }
        },
        destroyed () {
            this.resetAuth()
        }
    }
</script>
