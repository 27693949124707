import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import {AlertTheme, DefaultTheme} from '@windsor-telecom/windsor-framework/src/assets/theme/theme'

Vue.use(Vuetify, {
    theme: {
        ...AlertTheme,
        ...DefaultTheme
    }
})
