import './plugins/vuetify'
import '@/assets/stylus/main.styl'
import '@babel/polyfill'
import App from './App.vue'
import AppPlugin from '@/plugins/app'
import router from './router'
import store from './store'
import Vue from 'vue'
import {version} from '../package.json'
// Sentry
import * as Sentry from '@sentry/browser'
import {Integrations} from '@sentry/tracing'
import {Vue as VueIntegration} from '@sentry/integrations'

Vue.use(AppPlugin)
Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_ENV !== 'production'

// Sentry
if (process.env.VUE_APP_SENTRY_URL) {
    Sentry.init({
        release: version,
        dsn: process.env.VUE_APP_SENTRY_URL,
        integrations: [
            new VueIntegration({Vue, tracing: true, logErrors: true}),
            new Integrations.BrowserTracing()
        ]
    })
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
