import {v4 as uuid} from 'uuid'

export default {
    mapError: (err) => {
        const response = err.response
        const contentType = response.headers['content-type']
        const check = _.has(response, 'data')
            && contentType
            && contentType.indexOf('application/json') !== -1
        const data = check
            ? response.data
            : {type: 'unknown', message: 'An unknown error occurred'}
        const errors = data.errors.length !== 0
            ? data.errors
            : [{field: '', message: data.message}]

        switch (data.type) {
            case 'invalid_login_credentials':
            case 'validation':
                data.color = 'warning'
                data.showSnackbar = false
                break
            case 'invalid_client':
                data.showSnackbar = false
                break
            case 'error':
            case 'unknown':
            default:
                data.color = 'error'
                data.showSnackbar = true
        }

        return {uuid: uuid(), ...data, errors: errors, response: err}
    }
}
