export const MULTI_FACTOR_AUTH_PAGE = 'mfa-page'
export const LOGIN_PAGE = 'login'
export const NOT_FOUND_PAGE = 'not-found'
export const ERROR_PAGE = 'error-page'
export const ACTIVATION_PAGE = 'activation'
export const FORGOTTEN_PASSWORD_PAGE = 'forgotten-password'
export const RECOVERY_PAGE = 'recover-account'
export const RESET_PASSWORD_PAGE = 'reset-password'
export const CHANGE_PASSWORD_PAGE = 'change-password'
export const CLIENT_AUTHORISATION = 'client-authorisation'
