import * as mutationTypes from './mutation-types'
import api from '@/store/api'
import to from '@/store/utils/to'
import errorsUtil from '@/store/utils/errors'

const namespaced = true

const initialState = () => ({
    loading: true,
    valid: false,
    client: {
        name: '',
        client_id: '',
        public_key: '',
        redirect_url: '',
        tenant: {
            data: {
                theme: {}
            },
            id: '',
            logo_url: '',
            name: ''
        }
    }
})

const state = initialState()

const getters = {
    client: state => state.client,
    theme: state => state.client.tenant.data.theme,
    clientLogo: state => {
        if (state.client.tenant.logo_url) {
            return state.client.tenant.logo_url
        }

        return require('@/assets/logo.png')
    },
    redirectUrl: state => state.client.redirect_url,
    clientName: (state, getters, rootState, rootGetters) => {
        if (state.client.name) {
            return state.client.name
        }

        return rootGetters['app/title']
    },
    loading: state => state.loading,
    valid: state => state.valid
}

const actions = {
    checkCredentials: async ({commit, dispatch, state}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.checkCredentials(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            commit(mutationTypes.SET_VALID, false)
            return Promise.reject(errorsUtil.mapError(err))
        }

        commit(mutationTypes.SET_VALID, true)
        commit(mutationTypes.SET_CLIENT, response)

        return response
    },
    checkForcePasswordCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.checkForcePasswordCode(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        dispatch('user/setUserPasswordRequirements', response.data.password_requirements, {root: true})

        return response
    },
    checkMfaLoginCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.checkMfaLoginCode(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        dispatch('user/setUserFallbackOptions', {
            email_fallback: response.data.email_fallback,
            sms_fallback: response.data.sms_fallback
        }, {root: true})

        return response
    },
    login: async ({commit, dispatch, state}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.login(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    loginMfa: async ({commit, dispatch, state}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.loginMfa(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    forgottenPassword: async ({commit, dispatch}, emailAddress) => {
        let err
        let response

        [err, response] = await to(api.auth.forgottenPassword(emailAddress))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    checkForgottenPasswordCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.checkForgottenPasswordCode(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        dispatch('user/setUserPasswordRequirements', response, {root: true})

        return response
    },
    recoverAccount: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.recoverAccount(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    resetPassword: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.resetPassword(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    changePassword: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.changePassword(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    checkActivationCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.checkActivation(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        dispatch('user/setUserPasswordRequirements', response, {root: true})

        return response
    },
    activateUser: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.activateUser(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    sendEmailFallbackCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.sendEmailFallbackCode(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    sendSmsFallbackCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.sendSmsFallbackCode(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    checkClientAuthorisationCode: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.checkClientAuthorisationCode(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        dispatch('user/setUserPasswordRequirements', response, {root: true})

        return response
    },
    authoriseClient: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.authoriseClient(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    rejectClient: async ({commit, dispatch}, data) => {
        let err
        let response

        [err, response] = await to(api.auth.rejectClient(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        return response
    },
    setLoading: async ({commit}, data) => commit(mutationTypes.SET_LOADING, data),
    setCredentials: async ({commit}, data) => commit(mutationTypes.SET_CREDENTIALS, data),
    resetAuth: async ({commit}) => commit(mutationTypes.RESET_AUTH)
}

const mutations = {
    [mutationTypes.SET_CREDENTIALS] (state, data) {
        state.client = {...state.client, ...data}
    },
    [mutationTypes.SET_CLIENT] (state, data) {
        state.client = data
    },
    [mutationTypes.SET_LOADING] (state, data) {
        state.loading = data
    },
    [mutationTypes.SET_VALID] (state, data) {
        state.valid = data
    },
    [mutationTypes.RESET_AUTH] (state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
