<template>
    <v-app>
        <v-content>
            <w-page-loading
                :message="loadingMessage"
                :value="loading"
                hide-overlay
                progress-color="accent"
                v-if="loading"
            ></w-page-loading>
            <v-container fill-height fluid v-else>
                <v-layout align-center justify-center>
                    <v-flex class="text-xs-center" md4 sm8 xs12>
                        <slot></slot>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    import WPageLoading from '@windsor-telecom/windsor-framework/src/components/WPageLoading'

    export default {
        name: 'CenteredLayout',
        components: {
            WPageLoading
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            loadingMessage: {
                type: String,
                default: ''
            }
        }
    }
</script>
