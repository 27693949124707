import http from '@/http'
import mock from './utils/mock'
import login from '@/data/login'
import loginFail from '@/data/login-failed'
import client from '@/data/client'
import user from '@/data/user'

export default {
    checkCredentials (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                const items = _.pick(data, 'client_id', 'public_key')
                const check = _.every(items, (item) => Boolean(item))

                if (!check) {
                    return reject({response: {data: {type: 'error', message: 'Invalid credentials'}}})
                }

                return resolve(client.data())
            }

            http.get('/auth/client', {
                params: data
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    checkForcePasswordCode (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve(user.checkForcePasswordCodeData())
            }

            http.get(`/auth/force-password/${data.userId}/${data.code}`, {
                params: {
                    client_id: data.clientId,
                    public_key: data.publicKey,
                    account_code: data.accountCode
                }
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    checkMfaLoginCode (data) {
        return new Promise((resolve, reject) => {
            http.get(`/auth/login/mfa/${data.userId}/${data.code}`, {
                params: {
                    account_code: data.accountCode
                }
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    login (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                let check = {
                    account_code: 'ITSUPPOR',
                    email_address: 'it-support@windsor-telecom.co.uk',
                    password: 'password'
                }

                if (_.isMatch(data, check)) {
                    return resolve(login.data())
                }

                return reject({response: {data: loginFail.data()}})
            }

            http.post('/auth/login', data).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    checkForgottenPasswordCode (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            const url = `/auth/forgotten-password/${data.userId}/${data.forgottenPasswordCode}`

            http.get(url).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    forgottenPassword (emailAddress) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.post('/auth/forgotten-password', {
                'email_address': emailAddress
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    changePassword (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            const url = `/auth/force-password/${data.user_id}/${data.code}`

            http.post(url, {
                password: data.password,
                account_code: data.account_code
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    recoverAccount (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            const url = `/auth/login/mfa/${data.user_id}/${data.mfa_key}/recovery`

            http.post(url, {
                'client_id': data.client_id,
                'public_key': data.public_key,
                'account_code': data.account_code,
                'code': data.code
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    resetPassword (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            const url = `/auth/reset-password/${data.userId}/${data.forgottenPasswordCode}`

            http.post(url, {'password': data.password}).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    loginMfa (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            const url = `/auth/login/mfa/${data.user_id}/${data.code}`
            const request = {
                code: parseInt(_.values(data.codes).join('')),
                account_code: data.account_code
            }

            http.post(url, request).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    checkActivation (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.get(`/auth/account-activation/${data.userId}/${data.activationCode}`)
                .then((response) => {
                    resolve(response.data)
                }, (error) => reject(error))
        })
    },
    activateUser (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.post(`/auth/account-activation/${data.userId}/${data.activationCode}`, {
                'password': data.password
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    sendEmailFallbackCode (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.post(`/auth/login/mfa/${data.userId}/${data.code}/fallback/email`, {
                'account_code': data.accountCode
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    sendSmsFallbackCode (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.post(`/auth/login/mfa/${data.userId}/${data.code}/fallback/sms`, {
                'account_code': data.accountCode
            }).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    checkClientAuthorisationCode (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            const url = `/auth/client-authorisation/${data.userId}/${data.code}`

            http.get(url).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    authoriseClient (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.post(`/auth/client-authorisation/${data.userId}/${data.code}`).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    },
    rejectClient (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve()
            }

            http.post(`/auth/client-authorisation/${data.userId}/${data.code}/reject`).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    }
}
