export default {
    data: () => ({
        'name': 'Windsor Telecom Portal',
        'client_id': '0f83e6ca-2c7d-4bfe-9e10-cbcf5c5d17b5',
        'public_key': '1605669b-95ff-4eb6-9a00-8563a79bdfab',
        'redirect_url': 'https://www.windsor-telecom.co.uk',
        'tenant': {
            'id': '7569ec14-3879-48ba-8e4a-97d9fe7d273a',
            'name': 'Windsor Telecom',
            'logo_url': 'https://www.windsor-telecom.co.uk/wp-content/themes/windsortelecom/images/windsortelecom-logo.png',
            'data': {
                'theme': {
                    'info': '#4b9bd4',
                    'error': '#ed1c24',
                    'light': '#eeeeee',
                    'accent': '#626d78',
                    'primary': '#a90055',
                    'success': '#7fa73d',
                    'warning': '#ffcb04',
                    'secondary': '#003473'
                }
            }
        }
    })
}
