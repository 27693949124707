import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app/index'
import auth from './modules/auth/index'
import errors from './modules/errors/index'
import user from './modules/user/index'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const modules = {
    app,
    auth,
    errors,
    user
}

const store = new Vuex.Store({
    modules,
    strict: debug
})

export default store
