import * as routeNames from '@/router/route-names'

const LoginPage = () => import('@/pages/LoginPage.vue')
const ActivationPage = () => import('@/pages/ActivationPage.vue')
const ForgottenPasswordPage = () => import('@/pages/ForgottenPasswordPage.vue')
const RecoveryPage = () => import('@/pages/RecoveryPage.vue')
const ResetPasswordPage = () => import('@/pages/ResetPasswordPage.vue')
const ChangePasswordPage = () => import('@/pages/ChangePasswordPage.vue')
const MultiFactorAuthPage = () => import('@/pages/MultiFactorAuthPage.vue')
const ClientAuthorisationPage = () => import('@/pages/ClientAuthorisationPage')

export default [
    {
        path: '/',
        redirect: {name: routeNames.LOGIN_PAGE}
    },
    {
        path: '/login',
        name: routeNames.LOGIN_PAGE,
        component: LoginPage
    },
    {
        path: '/account-activation/:userId/:activationCode',
        name: routeNames.ACTIVATION_PAGE,
        props: true,
        component: ActivationPage
    },
    {
        path: '/reset-password/:userId/:forgottenPasswordCode',
        name: routeNames.RESET_PASSWORD_PAGE,
        props: true,
        component: ResetPasswordPage
    },
    {
        path: '/forgotten-password',
        name: routeNames.FORGOTTEN_PASSWORD_PAGE,
        props: true,
        component: ForgottenPasswordPage
    },
    {
        path: `/${routeNames.RECOVERY_PAGE}/:userId/:code`,
        name: routeNames.RECOVERY_PAGE,
        props: (route) => ({
            userId: route.params.userId,
            code: route.params.code,
            clientId: route.query.client_id,
            publicKey: route.query.public_key,
            emailAddress: route.query.email_address,
            accountCode: route.query.account_code
        }),
        component: RecoveryPage
    },
    {
        path: '/change-password/:userId/:code',
        name: routeNames.CHANGE_PASSWORD_PAGE,
        props: (route) => ({
            userId: route.params.userId,
            code: route.params.code,
            clientId: route.query.client_id,
            publicKey: route.query.public_key,
            emailAddress: route.query.email_address,
            accountCode: route.query.account_code
        }),
        component: ChangePasswordPage
    },
    {
        name: routeNames.MULTI_FACTOR_AUTH_PAGE,
        path: '/mfa/:userId/:code',
        props: (route) => ({
            userId: route.params.userId,
            code: route.params.code,
            emailAddress: route.query.email_address,
            accountCode: route.query.account_code
        }),
        component: MultiFactorAuthPage
    },
    {
        path: `/${routeNames.CLIENT_AUTHORISATION}/:userId/:code`,
        name: routeNames.CLIENT_AUTHORISATION,
        props: true,
        component: ClientAuthorisationPage
    },
]
