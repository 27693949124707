import * as mutationTypes from './mutation-types'

const namespaced = true

const initialState = () => ({
    title: 'SSO'
})

const state = initialState()

const getters = {
    title: state => state.title
}

const actions = {
    setTitle: async ({commit}, title) => commit(mutationTypes.SET_APP_TITLE, title),
    resetApp: async ({commit}, title) => commit(mutationTypes.RESET_APP)
}

const mutations = {
    [mutationTypes.SET_APP_TITLE] (state, title) {
        state.title = title
    },
    [mutationTypes.RESET_APP] (state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
