import Vue from 'vue'
import * as routeNames from '@/router/route-names'
import Router from 'vue-router'
import auth from './modules/auth'

const NotFoundPage = () => import('@/pages/NotFoundPage.vue')
const WErrorTest = () => import('@wf/components/WErrorTest')

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        ...auth,
        {
            name: routeNames.ERROR_PAGE,
            path: '/__error',
            meta: {requiresAuth: false, exclude: true},
            component: WErrorTest
        },
        {
            name: routeNames.NOT_FOUND_PAGE,
            path: '*',
            component: NotFoundPage
        }
    ]
})

const hasQueryParams = (route) => {
    return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({...to, query: from.query})
    } else {
        next()
    }
})

export default router
