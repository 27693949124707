import moment from 'moment/moment'
import md5 from 'md5'
import underscore from 'underscore'
import underscoreString from 'underscore.string'
import accounting from 'accounting'
import {v4 as uuid} from 'uuid'

import MetaPlugin from 'vue-meta'
import WRenderErrorMixin from '@windsor-telecom/windsor-framework/src/mixins/w-render-error-mixin'

import WSpan from '@windsor-telecom/windsor-framework/src/components/WSpan'

const clone = (obj) => JSON.parse(JSON.stringify(obj))

moment.defineLocale('no-time', {
    calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD/MM/YYYY'
    }
})

moment.locale('en-GB')
accounting.settings.currency.symbol = '£'
underscore.mixin(underscoreString.exports())

export default {
    install (Vue) {
        Vue.use(MetaPlugin)
        Vue.mixin(WRenderErrorMixin)

        Vue.component('w-span', WSpan)

        Object.defineProperties(Vue.prototype, {
            $clone: {value: clone},
            $moment: {value: moment},
            $accounting: {value: accounting},
            $_: {value: underscore},
            $uuid: {value: uuid},
            $md5: {value: md5}
        })
    }
}
