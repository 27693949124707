import http from '@/http'
import mock from './utils/mock'
import user from '@/data/user'

export default {
    getUserInfo (data) {
        return new Promise((resolve, reject) => {
            if (mock) {
                return resolve(user.getUserInfoData())
            }

            const url = `/auth/me/${data.userId}/${data.code}`
            const config = {
                params: {
                    client_id: data.clientId,
                    public_key: data.publicKey
                }
            }

            http.get(url, config).then((response) => {
                resolve(response.data)
            }, (error) => reject(error))
        })
    }
}
