import * as mutationTypes from './mutation-types'
import api from '@/store/api'
import to from '@/store/utils/to'
import errorsUtil from '@/store/utils/errors'

const namespaced = true

const initialState = () => ({
    user: {
        user_id: '',
        email_address: '',
        mfa_data: [],
        force_change_data: [],
        failed_attempts: 0,
        invitation_sent_at: null,
        invitation_sent_at_timestamp: null,
        activated_at: null,
        activated_at_timestamp: null,
        last_accessed_at: null,
        last_accessed_at_timestamp: null,
        password_requirements: {
            min_length: 4,
            min_numbers: 0,
            min_symbols: 0,
            min_lowercase: 0,
            min_uppercase: 0
        },
        fallback: {
            email_fallback: true,
            sms_fallback: true
        }
    }
})

const state = initialState()

const getters = {
    user: state => state.user,
    userPasswordRequirements: state => ({
        minLength: state.user.password_requirements.min_length,
        minNumbers: state.user.password_requirements.min_numbers,
        minSymbols: state.user.password_requirements.min_symbols,
        minLowercase: state.user.password_requirements.min_lowercase,
        minUppercase: state.user.password_requirements.min_uppercase
    }),
    userFallbackOptions: state => state.user.fallback
}

const actions = {
    getUserInfo: async ({commit, dispatch, state}, data) => {
        let err
        let response

        [err, response] = await to(api.user.getUserInfo(data))

        if (err) {
            dispatch('errors/addErrors', [err], {root: true})
            return Promise.reject(errorsUtil.mapError(err))
        }

        commit(mutationTypes.SET_USER, response)

        return response
    },
    setUser: async ({commit}, data) => commit(mutationTypes.SET_USER, data),
    setUserPasswordRequirements: async ({commit}, data) => commit(mutationTypes.SET_USER_PASSWORD_REQUIREMENTS, data),
    setUserFallbackOptions: async ({commit}, data) => commit(mutationTypes.SET_USER_FALLBACK_OPTIONS, data),
    resetUser: async ({commit}) => commit(mutationTypes.RESET_USER)
}

const mutations = {
    [mutationTypes.SET_USER] (state, data) {
        state.user = {...state.user, ...data}
    },
    [mutationTypes.SET_USER_PASSWORD_REQUIREMENTS] (state, data) {
        state.user.password_requirements = data
    },
    [mutationTypes.SET_USER_FALLBACK_OPTIONS] (state, data) {
        state.user.fallback = data
    },
    [mutationTypes.RESET_USER] (state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
