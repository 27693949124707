<template>
    <div>
        <v-flex xs12>
            <img :src="logoPath" width="256px">
        </v-flex>
        <v-flex mt-4 xs12>
            <w-span :span-class="spanClass">
                <slot></slot>
            </w-span>
        </v-flex>
    </div>
</template>

<script>
    export default {
        name: 'PageMessage',
        props: {
            logoPath: {
                type: String,
                required: true
            },
            spanClass: {
                type: String,
                default: 'display-1'
            }
        }
    }
</script>
