export default {
    data: () => ({
        'client_id': '0f83e6ca-2c7d-4bfe-9e10-cbcf5c5d17b5',
        'user_id': 'c1bf0149-a965-40e7-bea0-43a4ae416903',
        'email_address': 'it-support@windsor-telecom.co.uk',
        'account_code': null,
        'force_password_change': false,
        'state': 'redirect',
        'accessed_at': '2018-11-09 09:22:40',
        'hash': '0197581fb024cfcae48b514e3594eabca8693ea4'
    })
}
