// https://github.com/axios/axios
import axios from 'axios'
import store from '@/store'
import _ from 'underscore'

// https://github.com/axios/axios#request-config
let axiosHttp = axios.create(
    {
        baseURL: '/api',
        timeout: 60000,
        // `withCredentials` indicates whether or not cross-site Access-Control requests
        // should be made using credentials
        withCredentials: true
    }
)

axiosHttp.interceptors.request.use((config) => {
    const credentials = _.pick(store.getters['auth/client'], 'client_id', 'public_key')

    if (config.method === 'get') {
        config.params = {...config.params, ...credentials}
    } else {
        config.data = {...config.data, ...credentials}
    }

    return config
}, (error) => {
    return Promise.reject(error)
})

export default axiosHttp
