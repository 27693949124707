import * as mutationTypes from './mutation-types'
import errorsUtil from '@/store/utils/errors'

const namespaced = true

const initialState = () => ({
    errors: []
})

const state = initialState()

const getters = {
    errors: state => state.errors,
    hasErrors: state => state.errors.length > 0
}

const actions = {
    addErrors ({commit}, data) {
        const errors = _.map(data, (err) => errorsUtil.mapError(err))

        commit(mutationTypes.ADD_ERRORS, errors)
        return Promise.resolve()
    },
    removeErrors ({commit}, uuids) {
        commit(mutationTypes.REMOVE_ERRORS, uuids)
        return Promise.resolve()
    },
    resetErrors ({commit}) {
        commit(mutationTypes.RESET_ERRORS)
        return Promise.resolve()
    }
}

const mutations = {
    [mutationTypes.ADD_ERRORS] (state, data) {
        state.errors = [...state.errors, ...data]
    },
    [mutationTypes.REMOVE_ERRORS] (state, uuids) {
        state.errors = _.filter(state.errors, (err) => !_.contains(uuids, err.uuid))
    },
    [mutationTypes.RESET_ERRORS] (state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
