export default {
    getUserInfoData: () => ({
        'user_id': 'c1bf0149-a965-40e7-bea0-43a4ae416903',
        'email_address': 'andres.janes@windsor-telecom.co.uk',
        'has_mfa': false,
        'mfa_data': [],
        'enabled': true,
        'has_force_change': false,
        'force_change_data': [],
        'failed_attempts': 0,
        'invitation_sent_at': null,
        'invitation_sent_at_timestamp': null,
        'activated_at': null,
        'activated_at_timestamp': null,
        'last_accessed_at': null,
        'last_accessed_at_timestamp': null,
        'password_requirements': {
            'min_length': 8,
            'min_numbers': 1,
            'min_symbols': 1,
            'min_uppercase': 1,
            'min_lowercase': 1
        }
    }),
    checkForcePasswordCodeData: () => ({
        'client_id': '0f83e6ca-2c7d-4bfe-9e10-cbcf5c5d17b5',
        'user_id': 'c1bf0149-a965-40e7-bea0-43a4ae416903',
        'email_address': 'andres.janes@windsor-telecom.co.uk',
        'account_code': 'ITSUPPOR',
        'accessed_at': '2019-01-31 15:35:01',
        'timestamp': 1548948901,
        'state': 'force_password_change',
        'data': {
            'type': {
                'id': 1,
                'name': 'account inactivity for 60 days.'
            },
            'code': '15a74d3c-84bb-4a83-af48-5c1039e439e5',
            'password_requirements': {
                'min_length': 8,
                'min_numbers': 1,
                'min_symbols': 1,
                'min_uppercase': 1,
                'min_lowercase': 1
            }
        },
        'user_type': null,
        'legacy_id': null
    })
}
